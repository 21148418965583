



































































































import type { DataTableHeader } from 'vuetify';
import { InfoListPanel } from '@/components/InfoList/InfoListPanel.vue';
import { numberStyles } from '../ProjectedInventory.vue';
import { ProjectedInventoryGraph } from './ProjectedInventoryGraph.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import type { InfoListSection } from '@/lib/types';
import type { ProjectedInventoryRow } from '../ProjectedInventory.vue';
import Utility from '@/tools/Utility';

type ProjectedInventoryIntervalRow = {
  date: string;
  ur: number;
  po: number;
  ao: number;
};

@Component({
  name: 'ProjectedInventoryInterval',
  components: {
    ProjectedInventoryGraph,
    InfoListPanel,
  },
})
export class ProjectedInventoryInterval extends Vue {
  @Prop({ required: true })
  protected readonly interval!: ProjectedInventoryRow;

  @Prop({ required: true, validator: (value) => ['table', 'panel'].includes(value) })
  protected readonly displayType!: 'table' | 'panel';

  protected showVisuals = true;

  protected numberStyles = numberStyles;

  protected headers: (DataTableHeader & { meta?: Record<string, any> })[] = [
    {
      text: '',
      value: 'type',
      width: 50,
      align: 'start',
      meta: {
        tooltip: 'Transaction Type (PO, CIS)',
      },
    },
    {
      text: 'Estimated Date',
      value: 'date',
      meta: {
        tooltip: 'Estimated Date',
      },
    }, {
      text: 'UR',
      value: 'ur',
      width: 80,
      align: 'center',
      meta: {
        tooltip: 'Unreleased Quantity',
      },
    }, {
      text: 'PO',
      value: 'po',
      width: 80,
      align: 'center',
      meta: {
        tooltip: 'Purchase Order Quantity',
      },
    }, {
      text: 'AO',
      value: 'ao',
      width: 80,
      align: 'center',
      meta: {
        tooltip: 'At-Once Quantity',
      },
    },
  ];

  protected get isPanel(): boolean {
    return this.displayType === 'panel';
  }

  protected get isTable(): boolean {
    return this.displayType === 'table';
  }

  protected get rows(): ProjectedInventoryIntervalRow[] {
    return this.interval.entries.map((entry) => ({
      type: entry.transactionType,
      date: Utility.formatDate(entry.arrivalDate),
      ur: entry.pendingRelease || 0,
      po: entry.arrivalQuantity,
      ao: entry.quantity,
    }));
  }

  protected get sections(): InfoListSection[] {
    return this.interval.entries.map((entry) => ({
      label: Utility.formatDate(entry.arrivalDate),
      items: [
        {
          label: 'SKU',
          value: entry.sku.id,
        }, {
          label: 'Purchase Order',
          value: String(entry.arrivalQuantity),
        }, {
          label: 'At Once',
          value: String(entry.quantity),
        }, {
          label: 'Estimated Arrival Date',
          value: entry.arrivalDate,
        },
      ],
    }));
  }

  protected visualize() {
    this.showVisuals = !this.showVisuals;
  }
}

export default ProjectedInventoryInterval;

















import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import {
  CategoryScale,
  Chart,
  ChartDataset,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  TooltipOptions,
} from 'chart.js';
import { Utility } from '@/tools/Utility';
import { debounce } from 'lodash';
import type { ProjectedInventoryRow } from '../ProjectedInventory.vue';
import { ProjectedInventoryDatum } from '@/services';

Chart.register(
  LinearScale,
  CategoryScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
);

@Component({
  name: 'ProjectedInventoryGraph',
})
export class ProjectedInventoryGraph extends Vue {
  @Prop({ required: true })
  protected readonly interval!: ProjectedInventoryRow;

  @Ref('container')
  private readonly containerRef!: HTMLDivElement;

  @Ref('chart')
  private readonly chartRef!: HTMLCanvasElement;

  protected graphHeight: string | null = null;

  protected graphWidth: string | null = null;

  private chart?: Chart<'line', { x: string; y: number; }[], unknown>;

  private debouncedResize = debounce(this.resize, 300);

  private tooltipStyles: Partial<TooltipOptions<'line'>> = {
    // Background color of the tooltip.
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // Show/hide color boxes for each dataset in the tooltip
    displayColors: false,
    // Horizontal alignment of the title text lines. more...
    titleAlign: 'center',
    // Color of title text.
    titleColor: '#fff',
    // See Fonts.
    titleFont: { weight: 'bold', family: 'Roboto', size: 14, style: 'normal', lineHeight: 2 },
    // Margin to add on bottom of title section.
    titleMarginBottom: 6,
    // Spacing to add to top and bottom of each title line.
    titleSpacing: 2,
    // Horizontal alignment of the body text lines. more...
    bodyAlign: 'left',
    // Color of body text.
    bodyColor: '#fff',
    // See Fonts.
    bodyFont: { weight: 'bold', family: 'Roboto', size: 12, style: 'normal', lineHeight: 1 },
    // Spacing to add to top and bottom of each tooltip item.
    bodySpacing: 8,
  };

  /** Common dataset options */
  private datasetOptions = {
    fill: true,
    pointHitRadius: 25,
    pointHoverRadius: 5,
    pointRadius: 6,
    tension: 0.1,
  };

  public mounted() {
    this.graphWidth = String(this.containerRef.clientWidth);
    this.graphHeight = String(this.containerRef.clientHeight);
    this.$nextTick(this.drawGraph);

    window.addEventListener('resize', this.onResize);
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  protected onResize() {
    this.debouncedResize();
  }

  protected drawGraph() {
    const ctx = this.chartRef;
    if (!ctx || this.chart) return;

    this.chart = new Chart(ctx, {
      type: 'line',
      data: {
        datasets: [
          // At-Once data
          this.newDataset({
            label: 'At-Once Quantity',
            // Coloring specific to this dataset
            pointBackgroundColor: this.$vuetify.theme.currentTheme.primary?.toString(),
            pointBorderColor: this.$vuetify.theme.currentTheme.primary?.toString(),
            borderColor: this.$vuetify.theme.currentTheme.primary?.toString(),
          }, (entry) => ({
            x: Utility.formatDate(entry.arrivalDate),
            y: entry.quantity ?? 0,
          })),
        ],
      },
      options: {
        interaction: {
          mode: 'nearest',
        },
        plugins: {
          tooltip: this.tooltipStyles as any,
        },
        scales: {
          x: {
            ticks: { align: 'start' },
          },
          y: {
            type: 'linear',
            display: 'auto',
            beginAtZero: false,
            ticks: {
              precision: 0,

              callback: (value) => {
                if (value === 0) return `⟶ ${value}`;
                return value;
              },
            },
          },
        },
      },
    });
  }

  /**
   * Create and return a new Chart.js (line) dataset for our interval.
   */
  private newDataset(
    options: Partial<ChartDataset<'line', { x: string; y: number; }[]>>,
    cb: (entry: ProjectedInventoryDatum) => { x: string, y: number },
  ): ChartDataset<'line', { x: string; y: number; }[]> {
    const data = this.interval.entries.map(cb).sort((a, b) => {
      if (a.x < b.x) {
        return -1;
      }
      if (a.x > b.x) {
        return 1;
      }
      return 0;
    });

    return {
      ...this.datasetOptions,
      ...options,
      data,
    };
  }

  private resize() {
    this.graphWidth = String(this.containerRef.clientWidth);
    this.graphHeight = String(this.containerRef.clientHeight);

    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
    this.$nextTick(this.drawGraph);
  }
}

export default ProjectedInventoryGraph;

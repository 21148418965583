































































































import { Component, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import ReportUtil from '@/lib/ReportUtil';
import reportDownloader from '@/lib/ReportDownloader';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import { ReportComponent } from './classes/ReportComponent';
import { CalculatedDateTimeRange } from '../../../lib/types';
import { DataTableHeader } from 'vuetify';

let isAlternateCategory = false;

type PHSAOrderCalculationHeader = {
  units?: string;
  calculated?: boolean;
};

@Component({
  name: 'PHSAOrderCalculation',
})
export default class PHSAOrderCalculation extends ReportComponent {
  public readonly className = 'phsa-order-calculation';

  public readonly name = 'PHSA Order Calculation Report';

  public readonly description = 'Summary of Provincial Health Services Authority order data';

  public readonly enabledControls = {
    search: true,
    dateTimeRange: true,
    distChannels: false,
    displayByContainers: true,
    minOnHandDays: true,
  };

  public actions = [
    {
      label: 'Export as CSV',
      value: 'export_csv',
      icon: 'mdi-file-delimited',
    },
    {
      label: 'Export Raw Data',
      value: 'export_raw_data',
      icon: 'mdi-download',
    },
    {
      label: 'Export as PDF',
      value: 'export_pdf',
      icon: 'mdi-file-delimited',
    },
  ];

  protected data: any[] = [];

  private calculatedDateTimeRange: CalculatedDateTimeRange | null = null;

  private rawData: any[] = [];

  private get headers(): (DataTableHeader & PHSAOrderCalculationHeader)[] {
    return [
      {
        text: 'Category',
        value: 'category',
      },
      {
        text: 'SKU',
        value: 'sku',
      },
      {
        text: 'Description',
        value: 'description',
      },
      {
        text: 'Current Stock',
        value: 'currentStock',
      },
      {
        text: 'Back Order',
        value: 'backOrder',
      },
      {
        text: 'Sum of Shipped',
        value: 'totalShipped',
      },
      {
        text: 'Min On Hand',
        units: `${this.minOnHandDays} days`,
        value: 'minOnHand',
        calculated: true,
      },
      {
        text: 'Est PHSA Stock',
        units: `${this.minOnHandDays + 4} days`,
        value: 'estimatedPhsaOrder',
        calculated: true,
      },
      {
        text: 'PHSA Order',
        units: `${this.minOnHandDays + 4} days`,
        value: 'phsaOrder',
      },
    ];
  }

  /**
   * Callback for date-range watcher; reloads data from server
   * with new query parameters
   */
  @Watch('dateTimeRange')
  protected async onControlChange() {
    this.$nextTick(() => this.debouncedInit());
  }

  /**
   * Callback for displayByContainers watcher; recalculates data for display
   */
  @Watch('displayByContainers')
  protected onDisplayByContainersChange() {
    this.data = this.formatData(this.rawData);
  }

  /**
   * Callback for minOnHandDays watcher; recalculates data for display
   */
  @Watch('minOnHandDays')
  protected onMinOnHandDaysChange() {
    this.data = this.formatData(this.rawData);
  }

  public beforeCreate() {
    this.ReportUtil = ReportUtil;
  }

  public created() {
    this.toggleControls();
    this.calculatedDateTimeRange = dateTimeRangeCalculator.calcDateTimeRange(this.dateTimeRange);
    this.init();
  }

  public beforeUpdate() {
    isAlternateCategory = false;
  }

  public execute(command: string): void {
    if (command === 'export_csv') {
      this.downloadCsvReport();
      return;
    }
    if (command === 'export_raw_data') {
      this.downloadCsvRawData();
    }
    if (command === 'export_pdf') {
      this.downloadPdfReport();
    }
  }

  protected pdfHeight() {
    return this.tableDimensions().height * 0.225;
  }

  protected pdfWidth() {
    return this.tableDimensions().width * 0.3;
  }

  protected async fetchData(): Promise<any> {
    const authenticationToken = this.userService.getActiveToken();
    const response = await this.reportService.api.ppeSupply({
      authentication_token: authenticationToken,
      report_params: {
        startDate: this.calculatedDateTimeRange?.startDateTime,
        endDate: this.calculatedDateTimeRange?.endDateTime,
      },
    });
    this.rawData = response;
    return this.formatData(response);
  }

  /**
   * Alternate Category
   */
  protected alternateCategory(items: any[], i: number) {
    if (i !== 0 && items[i - 1].category !== items[i].category) {
      isAlternateCategory = !isAlternateCategory;
    }
    return isAlternateCategory;
  }

  /**
   * On Display Type Change
   */
  protected onDisplayTypeChange() {
    this.data = this.formatData(this.rawData);
  }

  /**
   * Download Csv Report
   */
  private downloadCsvReport() {
    const { startDate, endDate } = this.dateTimeRange;
    const filename = `${this.name} [${startDate} - ${endDate}]`;
    const data = this.data.map((row: any) => [
      row.category,
      row.sku,
      row.description,
      row.currentStock,
      row.backOrder,
      row.totalShipped,
      row.minOnHand,
      row.estimatedPhsaOrder,
      row.phsaOrder,
    ]);
    const headers = this.headers.map(head => head.text);
    data.unshift(headers);
    reportDownloader.downloadCsv(filename, data);
  }

  /**
   * Download Csv Raw Data
   */
  private downloadCsvRawData() {
    const { startDate, endDate } = this.dateTimeRange;
    const filename = `${this.name} [${startDate} - ${endDate}] (raw)`;
    const headers = Object.keys(this.rawData[0]);
    const data = [headers].concat(this.rawData.map(row => Object.values(row)));
    reportDownloader.downloadCsv(filename, data);
  }

  /**
   * Format Data
   */
  private formatData(data: any) {
    if (!this.calculatedDateTimeRange) {
      throw Error('Unable to format data with calculated date-range');
    }

    const { totalDays } = this.calculatedDateTimeRange;

    // group data
    let nestedReportData;
    if (this.displayByContainers) {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.item_category)
        .key((d: any) => d.sku)
        .rollup((leaves: any) => ({
          description: leaves[0].description,
          currentStock: d3.mean(leaves, (o: any) =>
            parseInt(o.packs_on_hand_main, 10),
          ),
          backOrder: d3.mean(leaves, (o: any) => parseInt(o.back_ordered_packs, 10)),
          totalShipped: d3.mean(leaves, (o: any) =>
            parseInt(o.packs_shipped_main, 10),
          ),
          averageDailyShipped: d3.mean(leaves, (o: any) => {
            const amount = (1.0 * o.packs_shipped_main) / totalDays;
            return parseInt(amount.toString(), 10);
          }),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    } else {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.item_category)
        .key((d: any) => d.sku)
        .rollup((leaves: any) => ({
          description: leaves[0].description,
          currentStock: d3.mean(leaves, (o: any) =>
            ReportUtil.calculateTotalUnits(
              o.packs_on_hand_main,
              o.unit_by_container,
            ),
          ),
          backOrder: d3.mean(leaves, (o: any) =>
            ReportUtil.calculateTotalUnits(
              o.back_ordered_packs,
              o.unit_by_container,
            ),
          ),
          totalShipped: d3.mean(leaves, (o: any) =>
            ReportUtil.calculateTotalUnits(
              o.packs_shipped_main,
              o.unit_by_container,
            ),
          ),
          averageDailyShipped: d3.mean(leaves, (o: any) =>
            ReportUtil.calculateTotalUnits(
              (1.0 * o.packs_shipped_main) / totalDays,
              o.unit_by_container,
            ),
          ),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    }
    // sort category and skus based on ascending sku priority
    nestedReportData.sort((a, b) =>
      d3.ascending(
        ReportUtil.getCategoryMinSkuPriority(a),
        ReportUtil.getCategoryMinSkuPriority(b),
      ),
    );
    nestedReportData.forEach(sku => {
      sku.values.sort((a: any, b: any) =>
        d3.ascending(a.minSkuPriority, b.minSkuPriority),
      );
    });
    // flatten report data for v-data-table component
    let flatReportData: any[] = [];
    nestedReportData.forEach((d: any) => {
      flatReportData = flatReportData.concat(
        d.values.map((o: any) => ({
          category: ReportUtil.stringifyField(d.key),
          sku: ReportUtil.stringifyField(o.key),
          description: ReportUtil.stringifyField(o.value.description),
          currentStock: o.value.currentStock,
          backOrder: o.value.backOrder,
          totalShipped: o.value.totalShipped,
          minOnHand: o.value.averageDailyShipped * this.minOnHandDays,
          estimatedPhsaOrder:
            o.value.currentStock - o.value.averageDailyShipped * (this.minOnHandDays + 4),
          phsaOrder: this.calculatePhsaOrder(
            o.value.currentStock - o.value.averageDailyShipped * (this.minOnHandDays + 4),
          ),
        })),
      );
    });
    // grand total
    flatReportData.push({
      category: 'Grand Total',
      sku: '',
      description: '',
      currentStock: d3.sum(flatReportData, o => o.currentStock),
      backOrder: d3.sum(flatReportData, o => o.backOrder),
      totalShipped: d3.sum(flatReportData, o => o.totalShipped),
      minOnHand: d3.sum(
        flatReportData,
        o => o.minOnHand,
      ),
      estimatedPhsaOrder: d3.sum(flatReportData, o => o.estimatedPhsaOrder),
      phsaOrder: d3.sum(flatReportData, o => o.phsaOrder),
    });
    return flatReportData;
  }

  /**
   * Calculate column PHSA order.
   * @param {number} estimatedPhsaOrder
   * @return {number | string} number or empty string
   */
  private calculatePhsaOrder(estimatedPhsaOrder: number) {
    return estimatedPhsaOrder < 0 ? Math.abs(estimatedPhsaOrder) : '';
  }
}

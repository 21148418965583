


























































































































































































import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';
import { Logger } from '@/tools/Logger';
import { Page } from '@/components/Page.vue';
import { ProjectedInventoryDatePicker } from './components/ProjectedInventoryDatePicker.vue';
import { ProjectedInventoryInterval } from './components/ProjectedInventoryInterval.vue';
import { ProjectedInventoryResponse, ReportService } from '@/services/ReportService';
import type { DataTableHeader } from 'vuetify';
import type { ProjectedInventoryDatum } from '@/services/ReportService';
import Utility from '@/tools/Utility';

export type ProjectedInventoryRow = {
  uid: string;
  entries: ProjectedInventoryDatum[];
};

export function numberStyles(value: number): string[] {
  const classList: string[] = ['font-weight-bold'];

  if (value < 0) classList.push('error--text');
  else if (value === 0) classList.push('text--disabled');
  else classList.push('primary--text text--lighten-1');

  return classList;
}

export function sortEntriesByDate(a: ProjectedInventoryDatum, b: ProjectedInventoryDatum): number {
  if (a.arrivalDate < b.arrivalDate) {
    return -1;
  }
  if (a.arrivalDate > b.arrivalDate) {
    return 1;
  }
  return 0;
}

@Component({
  name: 'ProjectedInventory',
  components: {
    Page,
    ProjectedInventoryInterval,
    ProjectedInventoryDatePicker,
  },
})
export class ProjectedInventory extends Vue {
  @Ref('page')
  private readonly pageRef!: Vue;

  @Ref('controls')
  private readonly controlsRef!: HTMLDivElement;

  protected search = '';

  protected pagination = {
    page: 1,
    itemsPerPage: 25,
    totalRecords: 0,
  };

  protected dates: [string, string?] = [new Date().toISOString().substr(0, 10)];

  protected data: ProjectedInventoryRow[] = [];

  protected expanded: any[] = [];

  protected highlightSearchText = Utility.highlightSearchText;

  protected numberStyles = numberStyles;

  protected loading = true;

  protected reportHeight = 0;

  /**
   * While we're using this report on Blastramp, these parameters will
   * store access data so we fire off requests to Blastramp's server.
   */
  protected blastrampParams: Record<string, any> = {
    VndCode: '',
    UserID: '',
    WHOVRD: '',
    vid: '',
  };

  /**
   * Flag determining if an extra "End Date" column should be rendered and
   * populated with data.
   */
  protected hasEndDate = false;

  private readonly logger: Logger = new Logger({ context: 'ProjectedInventory' });

  private readonly debouncedInit = debounce(this.init, 500);

  private readonly debouncedCalcReportHeight = debounce(this.calcReportHeight, 500);

  private readonly reportService = ReportService.getInstance();

  @Watch('pagination.page', { immediate: false })
  @Watch('pagination.itemsPerPage', { immediate: false })
  protected async watchPagination() {
    this.loading = true;
    this.data = [];
    await this.setQuery();
    this.debouncedInit();
  }

  @Watch('search', { immediate: false })
  protected async watchSearch(search: string) {
    this.loading = true;
    if (this.$route.query.search !== search) {
      this.pagination.page = 1;
    }
    await this.setQuery();
    this.debouncedInit();
  }

  public async mounted() {
    // Get all the query string parameters from the URL and into our component
    this.getQuery();

    // If we have an end date set, we want to render the "End Date" column
    // so we set this flag
    this.hasEndDate = !!this.dates[1];

    // Set the query string parameters (in case of parameters that were missing
    // from the URL to begin with)
    await this.setQuery();

    this.debouncedInit();
    this.debouncedCalcReportHeight();
  }

  protected async init() {
    this.loading = true;
    this.data = [];
    this.hasEndDate = !!this.dates[1];

    try {
      const response = await this.fetchData();
      this.data = this.formatData(response.data.results);
    } catch (error) {
      this.logger.error(error);
    }

    this.loading = false;
  }

  protected async fetchData(): Promise<ProjectedInventoryResponse> {
    this.getQuery();

    this.dates = this.dates.sort();

    const params = {
      startDate: this.dates[0],
      endDate: this.dates[1] ?? this.dates[0],
      page: this.pagination.page,
      perPage: this.pagination.itemsPerPage,
      search: this.search,
      ...this.blastrampParams,
    };

    const response = await this.reportService.api.projectedInventory(params);

    this.pagination.totalRecords = response.data.total;

    return response;
  }

  protected getHeaders(): DataTableHeader[] {
    const headers: (DataTableHeader & { meta?: Record<string, any> })[] = [
      {
        text: 'SKU',
        value: 'id',
        sortable: false,
        meta: {
          tooltip: 'SKU ID',
        },
      }, {
        text: 'Description',
        value: 'description',
        align: 'start',
        sortable: false,
        meta: {
          tooltip: 'SKU Description',
        },
      }, {
        text: 'Color',
        value: 'color',
        align: 'start',
        sortable: false,
        meta: {
          tooltip: 'SKU Color Variant',
        },
      }, {
        text: 'ATS',
        value: 'ats',
        sortable: false,
        align: 'center',
        width: 80,
        meta: {
          tooltip: 'Available To Sell',
        },
      }, {
        text: '# PO\'s',
        value: 'numPo',
        sortable: false,
        align: 'center',
        width: 80,
        meta: {
          tooltip: 'Number of PO\'s Containing SKU',
        },
      }, {
        text: 'AO',
        value: 'ao',
        sortable: false,
        align: 'center',
        width: 80,
        class: 'projected-inventory__start-date-column',
        meta: {
          tooltip: 'At-Once',
        },
      },
    ];

    if (this.hasEndDate) {
      headers.push({
        text: 'AO',
        value: 'aoEnd',
        sortable: false,
        align: 'center',
        width: 80,
        meta: {
          tooltip: 'At-Once',
        },
      });
    }

    return headers;
  }

  protected onPickDate() {
    this.setQuery();
    this.debouncedInit();
  }

  protected onResize() {
    this.debouncedCalcReportHeight();
  }

  /**
   * Get the available quantity to sell at the end of the specified
   * date range
   */
  protected getAvailableToSell(row: ProjectedInventoryRow): number {
    const lastIndex = row.entries.length - 1;
    return row.entries[lastIndex].quantity || 0;
  }

  /**
   * Get SKU details from a row (first element will be sufficient)
   */
  protected getSku(row: ProjectedInventoryRow): ProjectedInventoryDatum['sku'] {
    return row.entries[0].sku;
  }

  protected countPos(row: ProjectedInventoryRow): number {
    return row.entries.filter((entry) => entry.transactionType === 'PO').length;
  }

  private calcReportHeight(): void {
    // Height of the report controls
    const pageHeight = this.pageRef.$el.clientHeight;
    const pageHeaderRef = (this.pageRef.$refs['page-header'] as HTMLDivElement | undefined);
    const pageHeaderHeight = pageHeaderRef?.clientHeight ?? 0;
    const controlsHeight = this.controlsRef.clientHeight;

    // Hardcoded amount because I can't find where it's coming from 🤦
    const offset = 100;

    this.reportHeight = pageHeight - pageHeaderHeight - controlsHeight - offset;
  }

  private getQuery() {
    const {
      startDate,
      endDate,
      search,
      page,
      perPage,
      // Blastramp params
      VndCode,
      WHOVRD,
      UserID,
      vid,
    } = (this.$route.query as any);

    this.blastrampParams.VndCode = VndCode;
    this.blastrampParams.WHOVRD = WHOVRD;
    this.blastrampParams.UserID = UserID;
    this.blastrampParams.vid = vid;

    if (startDate) {
      this.dates = (this.dates[0] > startDate) ? [this.dates[0]] : [startDate];
      if (endDate && this.dates[0] <= endDate) this.dates.push(endDate);
    }
    if (search) {
      this.search = search as string;
    }
    if (page) {
      this.pagination.page = parseInt(page, 10);
    }
    if (perPage) {
      this.pagination.itemsPerPage = parseInt(perPage, 10);
    }
  }

  private async setQuery() {
    this.dates = this.dates.sort();

    const query: any = {
      startDate: this.dates[0],
      page: String(this.pagination.page),
      perPage: String(this.pagination.itemsPerPage),
      // Blastramp params
      VndCode: this.blastrampParams.VndCode,
      WHOVRD: this.blastrampParams.WHOVRD,
      UserID: this.blastrampParams.UserID,
      vid: this.blastrampParams.vid,
    };

    // Don't define an end-date if there's no difference between it and
    // the start-date, or if it's not defined at all
    if (this.dates[1] && this.dates[1] !== this.dates[0]) query.endDate = this.dates[1];

    // Only define the search query parameter if it's defined in the component
    if (this.search) query.search = this.search;

    // Lazy-compare our new query to our current query; ignore redirect
    // if they're the same
    const isSame = this.isSameQueryString(this.$route.query, query);
    if (isSame) return;

    try {
      return await this.$router.replace({
        name: 'projected-inventory',
        query,
      });
    } catch (error) {
      // Do nothing.
    }
  }

  /**
   * Compare two query objects (used to create URL query string) and return
   * if they're the same or not.
   */
  private isSameQueryString(queryA: Record<string, any>, queryB: Record<string, any>): boolean {
    const normalize = (s: string | number) => String(s).toLowerCase();
    const params = ['search', 'startDate', 'endDate', 'page', 'perPage'];
    return params.every((param) => normalize(queryA[param]) === normalize(queryB[param]));
  }

  private formatData(results: ProjectedInventoryResponse['data']['results']): ProjectedInventoryRow[] {
    return Object.values(results).map((result) => ({
      uid: result[0].sku.id,
      entries: result.sort(sortEntriesByDate),
    }));
  }
}

export default ProjectedInventory;

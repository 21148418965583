import { Emit, Vue } from 'vue-property-decorator';
import { IReportsState, EnabledReportControls } from '@/store/modules/reports';
import { Logger } from '@/tools/Logger';
import { Get, Sync } from 'vuex-pathify';
import type { DateTimeRange } from '@/lib/types/DateTime.type';
import { debounce } from 'lodash';
import FileSaver from 'file-saver';
import { ReportService, UserService } from '@/services';
import * as d3 from 'd3';

/**
 * `ReportComponent` is designed to abstract away redundant logic
 * when creating a new `Report` component. It's very opinionated,
 * and it all could become unusable eventually, but my hope is
 * that it will make our lives easier in the end.
 *
 * Things like initialization and debouncing hefty requests to the
 * server are taken care of - just be sure to utilize the flags
 * and fields defined here to ensure expected behavior.
 */
export abstract class ReportComponent extends Vue {
  [key: string]: any;

  /**
   * Loading indicator active during fetching of data, etc.
   */
  @Sync('reports/loading')
  protected loading!: IReportsState['loading'];

  /**
    * Height of the Report's table-body
    */
  @Get('layout/reportHeight')
  protected readonly reportHeight!: number;

  /**
    * Control for filtering rows by string
    */
  @Get('reports/search@value')
  protected readonly search!: string;

  /**
    * Control for filtering Report data by particular distribution channels
    */
  @Get('reports/distChannels@value')
  protected readonly distChannels!: string[];

  /**
    * Control for selecting a datetime range for querying Report data
    */
  @Get('reports/dateTimeRange@value')
  protected readonly dateTimeRange!: DateTimeRange;

  /**
    * Control for displaying results by container or otherwise
    */
  @Get('reports/displayByContainers@value')
  protected readonly displayByContainers!: number;

  /**
    * Control for PHSA Order Report Min On Hand Days calculation
    */
  @Get('reports/minOnHandDays@value')
  protected minOnHandDays!: number;

  /**
    * Search Enabled
    */
  @Sync('reports/search@enabled')
  protected isSearchEnabled!: boolean;

  /**
    * Date Time Range Enabled
    */
  @Sync('reports/dateTimeRange@enabled')
  protected isDateTimeRangeEnabled!: boolean;

  /**
    * Dist Channels Enabled
    */
  @Sync('reports/distChannels@enabled')
  protected isDistChannelsEnabled!: boolean;

  /**
    * Display By Containers Enabled
    */
  @Sync('reports/displayByContainers@enabled')
  protected isDisplayByContainersEnabled!: boolean;

  /**
    * Min On Hand Days Enabled
    */
  @Sync('reports/minOnHandDays@enabled')
  protected isMinOnHandDaysEnabled!: boolean;

  /**
    * Dark Theme Enabled
    */
  @Sync('context/theme@dark')
  private isDarkTheme!: boolean;

  /**
   * Report data
   */
  protected data: any[] = [];

  /**
   * Format numbers to include comma separator for groups of thousands
   */
  protected formatComma = d3.format(',');

  /**
   * Debounced init for limiting requests on Report controls/filtering
   */
  protected readonly debouncedInit = debounce(this.init, 500);

  protected readonly userService = UserService.getInstance();

  protected readonly reportService = ReportService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'ReportComponent' });

  /**
   * Class name of the report template
   */
  public abstract readonly className: string;

  /**
   * Name of the Report
   */
  public abstract readonly name: string;

  /**
   * Short description of the Report and it's purpose
   */
  public abstract readonly description: string;

  /**
   * Report actions in the overflow-menu. If no actions are
   * desired, set to an empty array, `[]`
   */
  public abstract readonly actions: ReportAction[];

  /**
   * Map of enabled controls for the Report
   */
  public abstract readonly enabledControls: EnabledReportControls;

  /**
   * Event emitter for a Report's setup
   * @param {any} [data]
   */
  @Emit('setup')
  protected emitReportSetup(data?: any) {
    return data ?? {};
  }

  /**
   * Reload the view
   */
  public reload() {
    // Cancel previous debounce (trailing edge) invocation to prevent double loading
    this.debouncedInit.cancel();
    this.init();
  }

  /**
   * Toggle on/off the controls
   */
  protected toggleControls(): void {
    this.isDateTimeRangeEnabled = this.enabledControls.dateTimeRange;
    this.isDisplayByContainersEnabled = this.enabledControls.displayByContainers;
    this.isDistChannelsEnabled = this.enabledControls.distChannels;
    this.isSearchEnabled = this.enabledControls.search;
    this.isMinOnHandDaysEnabled = this.enabledControls.minOnHandDays ?? false;
  }

  /**
   * Initialize the component
   */
  protected async init(): Promise<void> {
    this.loading = true;
    try {
      this.data = await this.fetchData();
      this.emitReportSetup({
        name: this.name,
        description: this.description,
        actions: this.actions,
      });
      this.loading = false;
    } catch (error) {
      if (error !== 'canceled') {
        this.loading = false;
        this.logger.error(error);
      }
    }
  }

  /**
   * Dimensions of the v-data-table table element (in pixels)
   */
  protected tableDimensions() {
    const reportDOM = document.getElementsByClassName(this.className)[0];
    const tableDOM = reportDOM.getElementsByTagName('table')[0];
    const height = tableDOM.offsetHeight;
    const width = tableDOM.offsetWidth;
    return { height, width };
  }

  /**
   * Download a PDF of the report using rendered html
   */
  protected async downloadPdfReport() {
    // Do nothing if data not available
    if (!this.data.length) return false;

    const isDarkThemeOn = this.isDarkTheme;
    if (isDarkThemeOn) this.isDarkTheme = false;
    // Wait for DOM update if theme switch triggered
    Vue.nextTick(async () => {
      const reportHTML = document.documentElement.innerHTML;
      // Switch dark theme back after grabbing DOM string
      if (isDarkThemeOn) this.isDarkTheme = true;

      const { startDate, endDate } = this.dateTimeRange;
      const filename = `${this.name} [${startDate} - ${endDate}]`;
      const authenticationToken = this.userService.getActiveToken();
      try {
        const response = await this.reportService.api.downloadPdf({
          authentication_token: authenticationToken,
          report_name: this.name,
          report_html: reportHTML,
          report_height: this.pdfHeight(),
          report_width: this.pdfWidth(),
        });
        if (response.size) FileSaver.saveAs(response, `${filename}.pdf`);
      } catch (error) {
        this.logger.error(error);
      }
    });
  }

  /**
   * Callback for actions selected from the overflow-menu in the
   * `ReportsControls` component
   */
  public abstract execute(command: string): void;

  /**
   * Fetch report data from API
   */
  protected abstract fetchData(): Promise<any>;

  /**
   * Pdf height.
   * Defined in subclass as each report has a different scaling factor.
   */
  protected abstract pdfHeight(): number;

  /**
    * Pdf width.
    * Defined in subclass as each report has a different scaling factor.
    */
  protected abstract pdfWidth(): number;
}

export type ReportAction = {
  /**
   * Label for option
   */
  label: string;
  /**
   * Value of the option
   */
  value: string;
  /**
   * Icon for option
   */
  icon: string;
};

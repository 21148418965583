var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-controls__wrapper"},[_c('div',{staticClass:"report-controls"},[_c('v-row',{attrs:{"align":"start","justify":"space-between"}},[(_vm.isSearchEnabled)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),(_vm.isDateTimeRangeEnabled)?[_c('v-col',{attrs:{"cols":"auto"}},[_c('date-time-range-picker',{ref:"dateTimeRangePicker",on:{"click:reload":_vm.onClickReload},model:{value:(_vm.dateTimeRange),callback:function ($$v) {_vm.dateTimeRange=$$v},expression:"dateTimeRange"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('date-time-range-preset-buttons',{on:{"click":_vm.onClickDateRangePresets},model:{value:(_vm.dateTimeRangePreset),callback:function ($$v) {_vm.dateTimeRangePreset=$$v},expression:"dateTimeRangePreset"}})],1),(_vm.isDisplayByContainersEnabled)?[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn-toggle',{attrs:{"active-class":"accent","dense":"","mandatory":""},model:{value:(_vm.displayByContainers),callback:function ($$v) {_vm.displayByContainers=$$v},expression:"displayByContainers"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"color":(!_vm.displayByContainers) ? 'white' : undefined}},[_vm._v(" mdi-package-variant ")])],1)]}}],null,false,462097645)},[_c('span',[_vm._v("Display by units")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('v-icon',{attrs:{"color":(_vm.displayByContainers) ? 'white' : undefined}},[_vm._v(" mdi-package-variant-closed ")])],1)]}}],null,false,4267509875)},[_c('span',[_vm._v("Display by containers")])])],1)],1)]:_vm._e()]:_vm._e(),(_vm.isMinOnHandDaysEnabled)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2","xl":"2"}},[_c('v-slider',{attrs:{"append-icon":"mdi-refresh","prepend-icon":"mdi-poll","min":"1","max":"31","thumb-label":"always","color":"accent","messages":"Min On Hand Days"},on:{"click:append":_vm.resetMinOnHandDays},model:{value:(_vm.minOnHandDays),callback:function ($$v) {_vm.minOnHandDays=$$v},expression:"minOnHandDays"}})],1):_vm._e(),(_vm.isDistChannelsEnabled)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"4"}},[_c('dist-channel-autocomplete',{attrs:{"label":"Report by Distribution Channel","chips":"","multiple":""},model:{value:(_vm.distChannels),callback:function ($$v) {_vm.distChannels=$$v},expression:"distChannels"}})],1):_vm._e(),_c('v-col',{staticClass:"d-flex justify-end"},[(_vm.actions && _vm.actions.length)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":_vm.loading,"color":"primary","text":""}},Object.assign({}, tooltip, menu)),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('span',[_vm._v("More actions")])])]}}],null,false,3947262634)},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.onAction(item.value)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(item.label)+" ")])],1)}),1)],1):_vm._e()],1)],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }


















































import { Vue, Component, PropSync, Ref, Emit, Prop } from 'vue-property-decorator';
import { VMenu } from 'vuetify/lib';

@Component({
  name: 'ProjectedInventoryDatePicker',
})
export class ProjectedInventoryDatePicker extends Vue {
  @PropSync('dates')
  protected syncedDates!: [string, string?];

  @Prop({ required: false, default: new Date().toISOString().substr(0, 10) })
  protected minimumDate!: string;

  @Ref('menu')
  protected readonly menuRef!: typeof VMenu;

  protected menu =  false;

  protected active = false;

  protected get dateRangeText() {
    return this.syncedDates.join(' ~ ');
  }

  protected get validDates(): boolean {
    return !!this.syncedDates[0];
  }

  @Emit('pick')
  protected onConfirm() {
    (this.menuRef as any).save(this.syncedDates);
    return this.syncedDates;
  }
}

export default ProjectedInventoryDatePicker;

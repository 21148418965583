var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[(!_vm.loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"height":_vm.reportHeight,"fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[_c('div',{class:{
                'text-right': !['category', 'sku', 'description'].includes(header.value),
                'phsa-order-calculation__color--calculated': header.calculated,
                'phsa-order-calculation__color--phsa-order': header.value === 'phsaOrder',
              }},[_c('div',[_vm._v(_vm._s(header.text))]),(header.units)?_c('div',[_vm._v(" ("+_vm._s(header.units)+") ")]):_vm._e()])])}),0)])]}},{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:item.sku,class:{
            'blue-lighten-5-bg-only': !_vm.$vuetify.theme.dark
              && _vm.alternateCategory(items, i) && i !== items.length - 1,
            'primary-darken-3-bg-only': _vm.$vuetify.theme.dark
              && _vm.alternateCategory(items, i) && i !== items.length - 1,
            'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark
              && i === items.length - 1,
            'yellow-darken-3-bg-only': _vm.$vuetify.theme.dark
              && i === items.length - 1,
            'font-weight-bold': i === items.length - 1,
          }},[_c('td',[(i === 0 || items[i - 1].category !== item.category)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.category))]):_vm._e()]),_c('td',[_vm._v(_vm._s(item.sku))]),_c('td',[_vm._v(_vm._s(item.description))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.currentStock))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.backOrder))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.totalShipped))+" ")]),_c('td',{staticClass:"text-right phsa-order-calculation__color--calculated"},[_vm._v(" "+_vm._s(_vm.formatComma(item.minOnHand))+" ")]),_c('td',{staticClass:"text-right phsa-order-calculation__color--calculated"},[_vm._v(" "+_vm._s(_vm.formatComma(item.estimatedPhsaOrder))+" ")]),_c('td',{staticClass:"text-right phsa-order-calculation__color--phsa-order"},[_vm._v(" "+_vm._s(_vm.formatComma(item.phsaOrder))+" ")])])}),0)]}}],null,false,1240257480)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }































































import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { Get, Sync } from 'vuex-pathify';
import ReportControls from './ReportControls.vue';
import { ReportAction } from '../classes/ReportComponent';
import type { DateTimeRange } from '@/lib/types/DateTime.type';

@Component({
  name: 'ReportContainer',
  components: {
    ReportControls,
  },
})
export default class ReportContainer extends Vue {
  @Get('reports/loading')
  protected loading!: boolean;

  @Sync('layout/reportHeight')
  protected reportHeight!: number;

  @Prop({ required: true, default: '[Report Name]' })
  protected reportName!: string;

  @Prop({ required: true, default: '[Report Description]' })
  protected reportDescription!: string;

  @Prop({ required: false, default: null })
  protected reportActions!: ReportAction[];

  @Ref('header')
  private readonly headerRef!: HTMLElement;

  @Ref('controls')
  private readonly controlsRef!: Vue;

  @Get('layout/totalHeight')
  private totalHeight!: number;

  @Get('layout/appBarHeight')
  private appBarHeight!: number;

  @Get('layout/contextBarHeight')
  private contextBarHeight!: number;

  @Get('reports/dateTimeRange@value')
  private dateTimeRange!: DateTimeRange;

  protected get dateTimeRangeText() {
    const { startDate, endDate, startEndTime } = this.dateTimeRange;
    return `${startDate} ${startEndTime} ~ ${endDate} ${startEndTime}`;
  }

  public beforeUpdate() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  public destroyed() {
    window.removeEventListener('resize', this.onResize);
  }

  protected onReload() {
    this.$emit('on-reload');
  }

  protected onAction(action: string) {
    this.$emit('on-action', action);
  }

  /**
   * Calculate the height of the Report we want to render
   */
  private calculateReportHeight(offset: number): number {
    const headerHeight = this.headerRef.getBoundingClientRect().height;
    const controlsHeight = this.controlsRef.$el.getBoundingClientRect().height;
    return this.totalHeight - this.appBarHeight - this.contextBarHeight - headerHeight - controlsHeight - offset;
  }

  private setReportHeight(height: number) {
    this.$nextTick(() => {
      this.reportHeight = this.calculateReportHeight(height);
    });
  }

  private onResize() {
    this.setReportHeight(110);
  }
}
















































































import { Component, Watch } from 'vue-property-decorator';
import reportDownloader from '@/lib/ReportDownloader';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import { ReportComponent, ReportAction } from './classes/ReportComponent';
import { DataTableHeader } from 'vuetify';
import { EnabledReportControls } from '../../../store/modules/reports';

@Component({
  name: 'OrderSummary',
})
export default class OrderSummary extends ReportComponent {
  public readonly enabledControls: EnabledReportControls = {
    search: true,
    dateTimeRange: true,
    distChannels: false,
    displayByContainers: false,
  };

  public readonly className = 'order-summary';

  public readonly name = 'Order Summary';

  public readonly description = 'Summary of Order data organized by distribution channels. Double-click a row to see more...';

  public actions: ReportAction[] = [
    {
      label: 'Export as CSV',
      value: 'export_csv',
      icon: 'mdi-file-delimited',
    },
    {
      label: 'Export as PDF',
      value: 'export_pdf',
      icon: 'mdi-file-delimited',
    },
  ];

  protected data: any[] = [];

  private headers: DataTableHeader[] = [
    {
      text: 'Distribution Channel',
      value: 'distribution_channel',
    },
    {
      text: 'Count Rush Created',
      value: 'count_rush_created',
    },
    {
      text: 'Count Standard Created',
      value: 'count_standard_created',
    },
    {
      text: 'Count Orders Created',
      value: 'count_orders_created',
    },
    {
      text: 'Count Rush Shipped',
      value: 'count_rush_shipped',
    },
    {
      text: 'Count Standard Shipped',
      value: 'count_standard_shipped',
    },
    {
      text: 'Count Orders Shipped',
      value: 'count_orders_shipped',
    },
    {
      text: 'Count Orders Not Filled 100',
      value: 'count_orders_not_filled_100',
    },
    {
      text: 'SLA MET Rush',
      value: 'sla_met_rush',
    },
    {
      text: 'SLA MET Standard',
      value: 'sla_met_standard',
    },
  ];

  /**
   * Callback for date-range watcher; reloads data from server
   * with new query parameters
   */
  @Watch('dateTimeRange')
  protected async onControlChange() {
    this.$nextTick(() => this.debouncedInit());
  }

  public created() {
    this.toggleControls();
    this.init();
  }

  public execute(command: string): void {
    if (command === 'export_csv') {
      return this.downloadCsvReport();
    }
    if (command === 'export_pdf') {
      this.downloadPdfReport();
    }
  }

  protected pdfHeight() {
    return this.tableDimensions().height * 0.25;
  }

  protected pdfWidth() {
    return this.tableDimensions().width * 0.3;
  }

  protected async fetchData() {
    if (!this.dateTimeRange) throw Error('Unable to fetch data without `dateTimeRange`');
    const { startDateTime, endDateTime } = dateTimeRangeCalculator.calcDateTimeRange(this.dateTimeRange);
    return this.reportService.api.rushSummary({
      authentication_token: this.userService.getActiveToken(),
      report_params: {
        startDate: startDateTime,
        endDate: endDateTime,
      },
    });
  }

  protected async viewDistChannelOrderSummary(distChannel: string) {
    if (distChannel === 'All Channels') return;
    await this.$router.push({
      name: 'dist-channel-order-summary',
      query: {
        distChannel,
      },
    });
    this.$destroy();
  }

  /**
   * Download CSV file of Report data
   */
  private downloadCsvReport() {
    if (!this.dateTimeRange) throw Error('Unable to download CSV without `dateTimeRange`');
    const dateRangeText = `${this.dateTimeRange.startDate} - ${this.dateTimeRange.endDate}`;
    const filename = `${this.name} [${dateRangeText}]`;
    const data = this.data.map((row) => [
      row.distribution_channel,
      row.count_rush_created,
      row.count_standard_created,
      row.count_orders_created,
      row.count_rush_shipped,
      row.count_standard_shipped,
      row.count_orders_shipped,
      row.count_orders_not_filled_100,
      row.sla_met_rush,
      row.sla_met_standard,
    ]);
    const headers = this.headers.map((head) => head.text);
    data.unshift(headers);
    reportDownloader.downloadCsv(filename, data);
  }
}

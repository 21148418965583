import * as d3 from 'd3';

/**
 * Utility class for shared logic between report views.
 */
export class ReportUtil {
  /**
   * Checks if value is zero or negative
   * @param int value
   * @return true/false
   */
  public isZeroOrNegative(int: string) {
    const parsedInt = parseInt(int, 10);
    return !Number.isNaN(parsedInt) && parsedInt <= 0;
  }

  /**
   * Gets the minimum sku priority value for a category tree.
   * @param category nested category data
   * @return minimum sku priority
   */
  public getCategoryMinSkuPriority(category: CategoryData) {
    return d3.min(
      category.values,
      (subCategory) => subCategory.value.minSkuPriority,
    );
  }

  /**
   * Calculates the total number of units
   * @param containers number of containers
   * @param [unitByContainer] unit per container
   * @return total number of units
   */
  public calculateTotalUnits(containers: number, unitByContainer?: string) {
    if (unitByContainer) {
      const parsedUnitByContainer = parseInt(unitByContainer, 10);
      if (!Number.isNaN(parsedUnitByContainer) && parsedUnitByContainer > 0) {
        return parseInt((containers * parsedUnitByContainer).toString(), 10);
      }
    }
    // if unit_by_container is unavailable, assume 1 unit/container
    return parseInt(containers.toString(), 10);
  }

  /**
   * Format float to percentage string.
   * If percentage is between 0 to 100% (non-inclusive), display to 2 decimal places.
   * @param val float number
   * @return formatted string
   */
  public floatToPercent(val: number) {
    if (val > 0 && val < 1) {
      return d3.format('.2%')(val);
    }
    return d3.format('.0%')(val);
  }

  /**
   * Calculate the fill-rate
   */
  public calculateFillRate(onOrderQuantity: number, backOrderQuantity: number) {
    const fillRate = (onOrderQuantity - backOrderQuantity) / onOrderQuantity;
    if (Number.isNaN(fillRate)) {
      return 0;
    }
    return fillRate;
  }

  /**
   * Stringify field if available otherwise return placeholder.
   *
   * @param {any} data input
   * @param {String} placeholder optional placeholder to display
   *
   * @returns {String} Display string.
   */
  public stringifyField(data: any, placeholder = '--') {
    if (data) {
      const out = data.toString().trim();
      return out || placeholder;
    }
    return placeholder;
  }
}

type CategoryData = {
  key: string;
  values: SubCategoryData[];
};

type SubCategoryData = {
  key: string;
  value: SubCategoryValue;
};

type SubCategoryValue = {
  minSkuPriority: number;
};

export default new ReportUtil();

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[(!_vm.loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"height":_vm.reportHeight,"fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[_c('div',{class:{ 'text-right': !['distribution_channel'].includes(header.value) }},[_vm._v(" "+_vm._s(header.text)+" ")])])}),0)])]}},{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:item.distribution_channel,class:{
            'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark && i === items.length -1 ,
            'yellow-darken-3-bg-only': _vm.$vuetify.theme.dark && i === items.length -1,
            'font-weight-bold': i === items.length -1,
          },on:{"dblclick":function($event){return _vm.viewDistChannelOrderSummary(item.distribution_channel)}}},[_c('td',[_vm._v(_vm._s(item.distribution_channel))]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_rush_created))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_standard_created))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_orders_created))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_rush_shipped))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_standard_shipped))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_orders_shipped))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.count_orders_not_filled_100))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.sla_met_rush))+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.formatComma(item.sla_met_standard))+" ")])])}),0)]}}],null,false,730176549)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }










































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import type { DateTimeRange } from '@/lib/types/DateTime.type';

@Component({
  name: 'DateTimeRangePicker',
})
export default class DateTimeRangePicker extends Vue {
  @Prop({ required: true, default: () => dateTimeRangeCalculator.getDateTimeRangeToday() })
  protected value!: DateTimeRange;

  @Prop({ required: false, default: 'PDT' })
  private timezoneText!: string;

  protected datePicker = false;

  protected timePicker = false;

  private dates: [string, string] = ['', ''];

  private datesConfirmed: [string, string] = ['', ''];

  private time = '';

  private timeConfirmed = '';

  protected get dateRangeText(): string {
    return `${this.datesConfirmed.join(' ~ ')} ${this.timeConfirmed} ${this.timezoneText}`;
  }

  protected get timeText(): string {
    return `${this.time} ${this.timezoneText}`;
  }

  @Watch('value', { immediate: true, deep: true })
  protected onValueChange(value: DateTimeRange) {
    this.datePicker = false;
    this.dates = [value.startDate, value.endDate];
    this.datesConfirmed = [value.startDate, value.endDate];
    this.timePicker = false;
    this.time = value.startEndTime;
    this.timeConfirmed = value.startEndTime;
  }

  protected allowedDates(date: string): boolean {
    return moment(date).isBetween(dateTimeRangeCalculator.FIRST_DAY, moment(), undefined, '[]');
  }

  protected onClickOpenDatePicker(): void {
    this.datePicker = true;
    this.timePicker = false;
    this.dates = this.datesConfirmed;
    this.time = this.timeConfirmed;
  }

  protected onClickUpdateDateTimeRange(): void {
    this.datePicker = false;
    this.timePicker = false;
    this.dates = this.sortDates(this.dates);
    this.datesConfirmed = this.dates;
    this.timeConfirmed = this.time;
    const dateTimeRange = {
      startDate: this.datesConfirmed[0],
      endDate: this.datesConfirmed[1],
      startEndTime: this.timeConfirmed,
    };
    this.$emit('input', dateTimeRange);
  }

  protected onClickReload(): void {
    this.$emit('click:reload');
  }

  private sortDates(dates: [string, string]): [string, string] {
    let start = moment(dates[0]);
    let end = moment(dates[dates.length - 1]);

    if (start.diff(end, 'd') > 0) {
      const newEnd = start;
      start = end;
      end = newEnd;
    }

    return [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')];
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[(!_vm.loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"height":_vm.reportHeight,"fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header,i){return _c('th',{key:("header.value_" + i),attrs:{"colspan":header.width}},[_c('div',{class:{
                'text-left': i === 0 ,
                'text-center': i > 0,
              }},[_vm._v(" "+_vm._s(header.text)+" ")])])}),0)])]}},{key:"body",fn:function(ref){
              var headers = ref.headers;
              var items = ref.items;
return [_c('tbody',[_c('tr',[_c('th',{style:(_vm.tableBgColor)}),_vm._l((headers.slice(1)),function(header){return [_c('th',{key:((header.value) + "_created_label")},[_c('div',{staticClass:"text-right"},[_vm._v(" Shipped ")])]),_c('th',{key:((header.value) + "_shipped_label")},[_c('div',{staticClass:"text-right"},[_vm._v(" Fill Rate ")])]),_c('th',{key:((header.value) + "_not_filled_100_label")},[_c('div',{staticClass:"text-right text-no-wrap"},[_vm._v(" Value ($) ")])])]})],2),_vm._l((items),function(item,i){return _c('tr',{key:((item.serviceCategory) + "_" + (item.distChannel) + "_" + i),class:{
            'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark && i === items.length - 1 ,
            'yellow-darken-3-bg-only': _vm.$vuetify.theme.dark && i === items.length - 1,
            'font-weight-bold category-highlight': !item.distChannel,
          }},[_c('td',{staticClass:"text-left text-no-wrap",style:(_vm.tableBgColor)},[(item.serviceCategory === '_grand_total')?_c('span',[_vm._v("Grand Total")]):(item.serviceCategory && !item.distChannel)?_c('span',[_vm._v(_vm._s(item.serviceCategory))]):_c('span',[_vm._v("-- "+_vm._s(item.distChannel))])]),_vm._l((headers.slice(1)),function(header,j){return [_c('td',{key:((header.value) + "_created"),class:_vm.getColumnStyle(header.value, j)},[_vm._v(" "+_vm._s(_vm.formatComma(item[header.value].shipped))+" ")]),_c('td',{key:((header.value) + "_shipped"),class:_vm.getColumnStyle(header.value, j)},[_vm._v(" "+_vm._s(item[header.value].fill_rate)+" ")]),_c('td',{key:((header.value) + "_not_filled_100"),class:_vm.getColumnStyle(header.value, j)},[_vm._v(" "+_vm._s(_vm.formatComma(item[header.value].value))+" ")])]})],2)})],2)]}}],null,false,533087793)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }

















































































import { Component, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import ReportUtil from '@/lib/ReportUtil';
import reportDownloader from '@/lib/ReportDownloader';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import { ReportComponent, ReportAction } from './classes/ReportComponent';
import { DataTableHeader } from 'vuetify';
import { CalculatedDateTimeRange } from '@/lib/types/DateTime.type';

@Component({
  name: 'EOCPPESupply',
})
export default class EOCPPESupply extends ReportComponent {
  public readonly className = 'eoc-ppe-supply';

  public readonly name = 'EOC PPE Supply';

  public readonly description = 'Report of supply data for EOC PPE';

  public actions: ReportAction[] = [
    {
      label: 'Export as CSV',
      value: 'export_csv',
      icon: 'mdi-file-delimited',
    },
    {
      label: 'Export Raw Data',
      value: 'export_raw_data',
      icon: 'mdi-download',
    },
    {
      label: 'Export as PDF',
      value: 'export_pdf',
      icon: 'mdi-file-delimited',
    },
  ];

  public enabledControls = {
    search: true,
    dateTimeRange: true,
    distChannels: false,
    displayByContainers: true,
  };

  protected calculatedDateTimeRange: CalculatedDateTimeRange | null = null;

  protected rawData: any[] = [];

  protected data: any[] = [];

  private headers: DataTableHeader[] = [
    {
      text: 'Custom Category',
      value: 'customCategory',
    },
    {
      text: 'Peak Warehouse',
      value: 'peakWarehouse',
    },
    {
      text: 'Category',
      value: 'category',
    },
    {
      text: 'Sub-Categories',
      value: 'subCategories',
    },
  ];

  /**
   * Callback for date-range watcher; reloads data from server
   * with new query parameters
   */
  @Watch('dateTimeRange')
  protected async onControlChange() {
    this.$nextTick(() => this.debouncedInit());
  }

  /**
   * Callback for displayByContainers watcher; recalculates data for display
   */
  @Watch('displayByContainers')
  protected onDisplayByContainersChange() {
    this.data = this.formatData(this.rawData);
  }

  public beforeCreate() {
    this.ReportUtil = ReportUtil;
  }

  public created() {
    this.toggleControls();
    this.calculatedDateTimeRange = dateTimeRangeCalculator.calcDateTimeRange(this.dateTimeRange);
    this.init();
  }

  public execute(command: string): void {
    if (command === 'export_csv') {
      this.downloadCsvReport();
      return;
    }
    if (command === 'export_raw_data') {
      this.downloadCsvRawData();
    }
    if (command === 'export_pdf') {
      this.downloadPdfReport();
    }
  }

  protected pdfHeight() {
    return this.tableDimensions().height * 0.29;
  }

  protected pdfWidth() {
    return this.tableDimensions().width * 0.3;
  }

  protected async fetchData() {
    const authenticationToken = this.userService.getActiveToken();
    const data = await this.reportService.api.eocPpeSupply({
      authentication_token: authenticationToken,
      report_params: {
        startDate: this.calculatedDateTimeRange?.startDateTime,
        endDate: this.calculatedDateTimeRange?.endDateTime,
      },
    });
    this.rawData = data;
    return this.formatData(data);
  }

  /**
   * Download CSV Report
   */
  private downloadCsvReport() {
    const { startDate, endDate } = this.dateTimeRange;
    const filename = `${this.name} [${startDate} - ${endDate}]`;
    const headers = this.headers.map((head) => head.text);
    const data = [headers].concat(this.data.map((row) => [
      row.customCategory,
      row.peakWarehouse,
      row.category,
      row.subCategories,
    ]));
    reportDownloader.downloadCsv(filename, data);
  }

  /**
   * Download CSV Report as raw data
   */
  private downloadCsvRawData() {
    const { startDate, endDate } = this.dateTimeRange;
    const filename = `${this.name} [${startDate} - ${endDate}] (raw)`;
    const headers = Object.keys(this.rawData[0]);
    const data = [headers].concat(this.rawData.map((row) => Object.values(row)));
    reportDownloader.downloadCsv(filename, data);
  }

  private formatData(rawData: any) {
    const data = rawData.filter((d: any) => d.custom_category !== null);
    // group data
    let nestedReportData;
    if (this.displayByContainers) {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.custom_category)
        .rollup((leaves: any) => ({
          category: leaves[0].item_category,
          subCategories: leaves
            .map((o: any) => o.item_subcategory)
            .filter((item: any, index: number, array: any[]) => array.indexOf(item) === index)
            .join('\n'),
          peakWarehouse: d3.sum(leaves, (o: any) => parseInt(o.packs_on_hand_main, 10)),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    } else {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.custom_category)
        .rollup((leaves: any) => ({
          category: leaves[0].item_category,
          subCategories: leaves
            .map((o: any) => o.item_subcategory)
            .filter((item: any, index: number, array: any[]) => array.indexOf(item) === index)
            .join('\n'),
          peakWarehouse: d3.sum(leaves, (o: any) => (
            ReportUtil.calculateTotalUnits(o.packs_on_hand_main, o.unit_by_container)
          )),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    }
    // sort category and sub-category based on ascending sku priority
    nestedReportData.sort((a: any, b: any) => (a.value.minSkuPriority - b.value.minSkuPriority));
    // flatten report data for v-data-table component
    let flatReportData: any[] = [];
    nestedReportData.forEach((d: any) => {
      flatReportData = flatReportData.concat({
        customCategory: d.key,
        category: d.value.category,
        subCategories: d.value.subCategories,
        peakWarehouse: d.value.peakWarehouse,
      });
    });
    // grand total
    flatReportData.push({
      customCategory: 'Grand Total',
      category: '',
      subCategories: '',
      peakWarehouse: d3.sum(flatReportData, (o) => o.peakWarehouse),
    });

    return flatReportData;
  }
}

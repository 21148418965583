

































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import reportUtilInstance from '@/lib/ReportUtil';
import DateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import { User } from '@/models/internal';
import { DataTableHeader } from 'vuetify';
import { ReportComponent } from './classes/ReportComponent';
import { Utility } from '@/tools/Utility';

const logger = console;

@Component({
  name: 'DistChannelOrderSummary',
})
export default class DistChannelOrderSummary extends ReportComponent {
  @Prop({ required: true, default: 'All Channels' })
  private readonly distChannel!: string;

  public readonly className = 'dist-channel-order-summary';

  public readonly name = 'Distribution Channel Details';

  public readonly description = (this.distChannel)
    ? `Order summary data based on the distribution channel, "${this.distChannel}"`
    : 'Order summary data based on all available distribution channels';

  public actions = [
    {
      label: 'Export as PDF',
      value: 'export_pdf',
      icon: 'mdi-file-delimited',
    },
  ];

  public enabledControls = {
    search: true,
    dateTimeRange: true,
    distChannels: false,
    displayByContainers: true,
  };

  public data: any = [];

  protected reportHeaders: DataTableHeader[] = [];

  protected rushSelectedDatesRawData: any = [];

  protected rushSelectedDatesData: any = [];

  protected rushAllRawData: any = [];

  protected reportUtil = reportUtilInstance;

  private isIgnoreZeroRows = false;

  private ppeSelectedDatesRawData: any = [];

  private ppeAllRawData: any = [];

  private ppeAllData: any = [];

  private rushAllData: any = [];

  private get calculatedDateTimeRange() {
    return DateTimeRangeCalculator.calcDateTimeRange(this.dateTimeRange);
  }

  private get allDateTimeRange() {
    const allTimeRange = DateTimeRangeCalculator.getDateTimeRangeAll();
    return DateTimeRangeCalculator.calcDateTimeRange(allTimeRange);
  }

  protected get tableBgColor() {
    return {
      background: (this.$vuetify.theme.dark) ? '#1E1E1E' : 'white',
    };
  }

  protected get ready() {
    return this.data?.length
      && this.rushAllData?.length
      && this.ppeAllData?.length;
  }

  /**
     * Callback for date-range watcher; reloads data from server
     * with new query parameters
     */
  @Watch('dateTimeRange')
  protected async onControlChange() {
    this.$nextTick(() => this.debouncedInit());
  }

  /**
     * Callback for displayByContainers watcher; recalculates data for display
     */
  @Watch('displayByContainers')
  protected onDisplayByContainersChange() {
    this.data = this.formatPPEOrderData(this.ppeSelectedDatesRawData);
    this.ppeAllData = this.formatPPEOrderData(this.ppeAllRawData);
  }

  public created() {
    this.toggleControls();
    this.init();
  }

  public execute(command: string): void {
    if (command === 'export_pdf') {
      this.downloadPdfReport();
    }
  }

  protected pdfHeight() {
    return this.tableDimensions().height * 0.23;
  }

  protected pdfWidth() {
    return this.tableDimensions().width * 0.3;
  }

  protected async fetchData() {
    this.loadPPEAllData();
    this.loadRushSelectedDatesData();
    this.loadRushAllData();
    return this.loadPPESelectedDatesData();
  }

  /**
   * Load Ppe Selected Dates Data
   */
  protected async loadPPESelectedDatesData() {
    this.reportHeaders = [];
    // fetch week datetime range
    try {
      const user = User.getActive();
      const authenticationToken = user?.authentication_token;
      const response = await this.reportService.api.distChannelPpeOrderSummary({
        authentication_token: authenticationToken,
        report_params: {
          startDate: this.calculatedDateTimeRange.startDateTime,
          endDate: this.calculatedDateTimeRange.endDateTime,
          distChannel: this.distChannel,
        },
      });
      this.ppeSelectedDatesRawData = response;
      this.reportHeaders = this.getTableHeaders(response);

      return this.formatPPEOrderData(response);
    } catch (error) {
      logger.error('Failed to fetch PPE week data: ', error);
    }
  }

  /**
   * Load Ppe All Data
   */
  protected async loadPPEAllData() {
    this.ppeAllData = [];
    // fetch all datetime range
    try {
      const user = User.getActive();
      const authenticationToken = user?.authentication_token;
      const response = await this.reportService.api.distChannelPpeOrderSummary({
        authentication_token: authenticationToken,
        report_params: {
          startDate: this.allDateTimeRange.startDateTime,
          endDate: this.allDateTimeRange.endDateTime,
          distChannel: this.distChannel,
        },
      });
      this.ppeAllRawData = response;
      this.ppeAllData = this.formatPPEOrderData(response);
    } catch (error) {
      logger.error('Failed to fetch PPE all data: ', error);
    }
  }

  /**
   * Load Rush Selected Dates Data
   */
  protected async loadRushSelectedDatesData() {
    this.rushSelectedDatesData = [];
    // fetch week datetime range
    try {
      const user = User.getActive();
      const authenticationToken = user?.authentication_token;
      const response = await this.reportService.api.distChannelRushSummary({
        authentication_token: authenticationToken,
        report_params: {
          startDate: this.calculatedDateTimeRange.startDateTime,
          endDate: this.calculatedDateTimeRange.endDateTime,
          distChannel: this.distChannel,
        },
      });
      this.rushSelectedDatesRawData = response;
      this.rushSelectedDatesData = this.formatRushSummaryData(response);
    } catch (error) {
      logger.error('Failed to fetch rush summary week data: ', error);
    }
  }

  /**
   * Load Rush All Data
   */
  protected async loadRushAllData() {
    this.rushAllData = [];
    // fetch all datetime range
    try {
      const user = User.getActive();
      const authenticationToken = user?.authentication_token;
      const response = await this.reportService.api.distChannelRushSummary({
        authentication_token: authenticationToken,
        report_params: {
          startDate: this.allDateTimeRange.startDateTime,
          endDate: this.allDateTimeRange.endDateTime,
          distChannel: this.distChannel,
        },
      });
      this.rushAllRawData = response;
      this.rushAllData = this.formatRushSummaryData(response);
    } catch (error) {
      logger.error('Failed to fetch rush summary all data: ', error);
    }
  }

  /**
   * Get Table Headers
   */
  protected getTableHeaders(data: any) {
    let tableHeaders = [
      {
        text: 'Category',
        value: 'category',
        width: 1,
      },
    ];
    tableHeaders = tableHeaders.concat(this.getTableHeaderSites(data));
    tableHeaders.push({
      text: 'Grand Total',
      value: 'grandTotal',
      width: 2,
    });
    return tableHeaders;
  }

  /**
   * Get Table Header Sites
   */
  protected getTableHeaderSites(data: any) {
    const sites = d3
      .nest()
      .key((d: any) => d.retailer_code)
      .entries(data);
    return sites.map((site) => ({
      text: Utility.titleCase(site.values[0].site),
      value: site.key,
      width: 2,
    }));
  }

  /**
   * Format Data
   */
  protected formatPPEOrderData(data: any) {
    let nestedReportData;
    if (this.displayByContainers) {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.item_category)
        .key((d: any) => d.item_subcategory)
        .key((d: any) => d.retailer_code)
        .rollup((leaves) => ({
          onOrder: d3.sum(leaves, (o: any) => parseInt(o.created_packs, 10)),
          backOrder: d3.sum(leaves, (o: any) => parseInt(o.back_ordered_packs, 10)),
          shipped: d3.sum(leaves, (o: any) => parseInt(o.packs_shipped_main, 10)),
          fill: d3.mean(leaves, (o: any) => parseFloat(o.fill_rate)),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    } else {
      nestedReportData = d3
        .nest()
        .key((d: any) => d.item_category)
        .key((d: any) => d.item_subcategory)
        .key((d: any) => d.retailer_code)
        .rollup((leaves) => ({
          onOrder: d3.sum(leaves, (o: any) => reportUtilInstance.calculateTotalUnits(o.created_packs, o.unit_by_container)),
          backOrder: d3.sum(leaves, (o: any) => reportUtilInstance.calculateTotalUnits(o.back_ordered_packs, o.unit_by_container)),
          shipped: d3.sum(leaves, (o: any) => reportUtilInstance.calculateTotalUnits(o.packs_shipped_main, o.unit_by_container)),
          fill: d3.mean(leaves, (o: any) => parseFloat(o.fill_rate)),
          minSkuPriority: d3.min(leaves, (o: any) => parseInt(o.sku_priority, 10)),
        }) as any)
        .entries(data);
    }
    // flatten report data for v-data-table component
    let flatReportData: any[] = [];
    nestedReportData.forEach((d: any) => {
      // subcategories
      const subCategoryRows = d.values.map((o: any) => ({
        category: reportUtilInstance.stringifyField(d.key),
        subCategory: reportUtilInstance.stringifyField(o.key),
        sites: o.values.reduce((acc: any, site: any) => {
          acc[site.key] = site.value;
          return acc;
        }, {}),
      }));
      // category overall row
      flatReportData.push({
        category: reportUtilInstance.stringifyField(d.key),
        subCategory: '',
        sites: (() => {
          const categorySites = subCategoryRows.reduce((siteMap: any, subCategory: any) => {
            Object.entries(subCategory.sites).forEach(([siteKey, siteData]: [any, any]) => {
              if (!siteMap[siteKey]) {
                siteMap[siteKey] = Object.entries(siteData).reduce((dataMap: any, [key, val]) => {
                  dataMap[key] = [val];
                  return dataMap;
                }, {});
              } else {
                Object.entries(siteData).forEach(([key, val]) => {
                  siteMap[siteKey][key].push(val);
                });
              }
            });
            return siteMap;
          }, {});
          Object.values(categorySites).forEach((siteData: any) => {
            Object.keys(siteData).forEach((key) => {
              switch (key) {
                case 'fill':
                  siteData[key] = d3.mean(siteData[key]);
                  break;
                case 'minSkuPriority':
                  siteData[key] = d3.min(siteData[key]);
                  break;
                default:
                  siteData[key] = d3.sum(siteData[key]);
                  break;
              }
            });
          });
          return categorySites;
        })(),
      });
      flatReportData = flatReportData.concat(subCategoryRows);
    });
    // grand total row
    const subCategoryData = flatReportData.filter((o) => o.subCategory);
    flatReportData.push({
      category: 'Grand Total',
      subCategory: '',
      sites: (() => {
        const categorySites = subCategoryData.reduce((siteMap, subCategory) => {
          Object.entries(subCategory.sites).forEach(([siteKey, siteData]: [any, any]) => {
            if (!siteMap[siteKey]) {
              siteMap[siteKey] = Object.entries(siteData).reduce((dataMap: any, [key, val]) => {
                dataMap[key] = [val];
                return dataMap;
              }, {});
            }
            Object.entries(siteData).forEach(([key, val]) => {
              siteMap[siteKey][key].push(val);
            });
          });
          return siteMap;
        }, {});
        Object.values(categorySites).forEach((siteData: any) => {
          Object.keys(siteData).forEach((key) => {
            switch (key) {
              case 'fill':
                siteData[key] = d3.mean(siteData[key]);
                break;
              case 'minSkuPriority':
                siteData[key] = d3.min(siteData[key]);
                break;
              default:
                siteData[key] = d3.sum(siteData[key]);
                break;
            }
          });
        });
        return categorySites;
      })(),
    });
    // grand total column per row
    flatReportData.forEach((row) => {
      const siteData = Object.values(row.sites);
      const grandTotalCol = {
        onOrder: d3.sum(siteData, (o: any) => o.onOrder),
        backOrder: d3.sum(siteData, (o: any) => o.backOrder),
        shipped: d3.sum(siteData, (o: any) => o.shipped),
        fill: d3.mean(siteData, (o: any) => o.fill),
      };
      row.sites.grand_total = grandTotalCol;
    });
    return flatReportData;
  }

  /**
   * Format Rush Summary Data
   */
  protected formatRushSummaryData(data: any) {
    const rushSummaryData = [];
    // rush order row
    rushSummaryData.push({
      ordertype: 'Rush Order',
      sites: data.reduce((acc: any, site: any) => {
        const siteKey = site.retailer_code;
        acc[siteKey] = {
          count_rush_created: site.count_rush_created,
          count_rush_shipped: site.count_rush_shipped,
          sla_met_rush: site.sla_met_rush,
        };
        return acc;
      }, {}),
    });
    // standard order row
    rushSummaryData.push({
      ordertype: 'Standard Order',
      sites: data.reduce((acc: any, site: any) => {
        const siteKey = site.retailer_code;
        acc[siteKey] = {
          count_standard_created: site.count_standard_created,
          count_standard_shipped: site.count_standard_shipped,
          sla_met_standard: site.sla_met_standard,
        };
        return acc;
      }, {}),
    });
    // grand total row
    rushSummaryData.push({
      ordertype: 'Grand Total',
      sites: data.reduce((acc: any, site: any) => {
        const siteKey = site.retailer_code;
        acc[siteKey] = {
          count_orders_shipped: site.count_orders_shipped,
        };
        return acc;
      }, {}),
    });
    // grand total column per row
    rushSummaryData.forEach((row) => {
      const siteData = Object.values(row.sites);
      const grandTotalCol: any = {};
      switch (row.ordertype) {
        case 'Rush Order':
          grandTotalCol.count_rush_created = d3.sum(siteData, (o: any) => o.count_rush_created);
          grandTotalCol.count_rush_shipped = d3.sum(siteData, (o: any) => o.count_rush_shipped);
          grandTotalCol.sla_met_rush = d3.sum(siteData, (o: any) => o.sla_met_rush);
          break;
        case 'Standard Order':
          grandTotalCol.count_standard_created = d3.sum(siteData, (o: any) => o.count_standard_created);
          grandTotalCol.count_standard_shipped = d3.sum(siteData, (o: any) => o.count_standard_shipped);
          grandTotalCol.sla_met_standard = d3.sum(siteData, (o: any) => o.sla_met_standard);
          break;
        case 'Grand Total':
          grandTotalCol.count_orders_shipped = d3.sum(siteData, (o: any) => o.count_orders_shipped);
          break;
        default:
          break;
      }
      row.sites.grand_total = grandTotalCol;
    });
    return rushSummaryData;
  }

  /**
   * Get a Site-column key
   */
  protected getSiteColumnStyle(colKey: any, colIndex: any) {
    if (colKey === 'grand_total') {
      return {
        'yellow-lighten-5-bg-only': !this.$vuetify.theme.dark,
        'yellow-darken-3-bg-only': this.$vuetify.theme.dark,
        'text-right': true,
      };
    }
    if (colIndex % 2) {
      return {
        'text-right': true,
      };
    }
    return {
      'blue-lighten-5-bg-only': !this.$vuetify.theme.dark,
      'primary-darken-3-bg-only': this.$vuetify.theme.dark,
      'text-right': true,
    };
  }

  /**
   * Render a PPE data row
   */
  protected renderPPEDataRow(selectedDatesGrandTotal: any, allDatesGrandTotal: any) {
    if (this.isIgnoreZeroRows) {
      if (selectedDatesGrandTotal.shipped === 0
      && allDatesGrandTotal.shipped === 0) {
        return false;
      }
      return true;
    }
    return true;
  }
}

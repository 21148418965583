


























































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import DateTimeRangePicker from '@/components/DateTimeRange/DateTimeRangePicker.vue';
import DateTimeRangePresetButtons, {
  DateTimeRangePreset,
} from '@/components/DateTimeRange/DateTimeRangePresetButtons.vue';
import DistChannelAutocomplete from '@/components/Autocomplete/DistChannelAutocomplete.vue';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import type { DateTimeRange } from '@/lib/types/DateTime.type';
import { Sync, Get } from 'vuex-pathify';
import { ReportAction } from '../classes/ReportComponent';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'ReportControls',
  components: {
    DateTimeRangePicker,
    DateTimeRangePresetButtons,
    DistChannelAutocomplete,
  },
})
export default class ReportControls extends Vue {
  @Get('reports/loading')
  protected readonly loading!: boolean;

  @Get('reports/search@enabled')
  protected readonly isSearchEnabled!: boolean;

  @Get('reports/dateTimeRange@enabled')
  protected readonly isDateTimeRangeEnabled!: boolean;

  @Get('reports/distChannels@enabled')
  protected readonly isDistChannelsEnabled!: boolean;

  @Get('reports/displayByContainers@enabled')
  protected readonly isDisplayByContainersEnabled!: boolean;

  @Sync('reports/search@value')
  protected search!: string;

  @Sync('reports/dateTimeRange@value')
  protected dateTimeRange!: DateTimeRange;

  @Sync('reports/distChannels@value')
  protected distChannels!: string[];

  @Sync('reports/displayByContainers@value')
  protected displayByContainers!: number;

  @Sync('reports/minOnHandDays@value')
  protected minOnHandDays!: number;

  @Prop({ required: true, default: () => [] })
  protected actions!: ReportAction[];

  @Get('reports/minOnHandDays@enabled')
  private readonly isMinOnHandDaysEnabled!: boolean;

  /**
   * Preset range for the date-picker
   */
  private dateTimeRangePreset = DateTimeRangePreset.ALL;

  public created() {
    this.setDateRangePresets(this.dateTimeRangePreset);
  }

  public mounted() {
    if (this.isMinOnHandDaysEnabled) {
      this.resetMinOnHandDays();
    }
  }

  /**
   * Clicked presets from DateTimeRangePresetButtons.
   */
  protected onClickDateRangePresets(preset: DateTimeRangePreset) {
    this.setDateRangePresets(preset);
  }

  /**
   * Listener for reloading of table through date-picker
   */
  protected onClickReload() {
    this.$emit('on-reload');
  }

  /**
   * Listener for clicking an option from the overflow menu
   * dropdown
   */
  protected onAction(action: string) {
    this.$emit('on-action', action);
  }

  /**
   * Resets minOnHandDays to application config default value
   */
  private async resetMinOnHandDays() {
    this.minOnHandDays = (await Utility.fetchConfig()).defaults.reports.phsa_order_calculation.min_on_hold_days;
  }

  /**
   * Apply the date-range preset
   */
  private setDateRangePresets(preset: DateTimeRangePreset) {
    switch (preset) {
      case DateTimeRangePreset.DAY:
        this.dateTimeRange = dateTimeRangeCalculator.getDateTimeRangeToday();
        break;
      case DateTimeRangePreset.WEEK:
        this.dateTimeRange = dateTimeRangeCalculator.getDateTimeRangeThisWeek();
        break;
      case DateTimeRangePreset.MONTH:
        this.dateTimeRange = dateTimeRangeCalculator.getDateTimeRangeThisMonth();
        break;
      case DateTimeRangePreset.ALL:
        this.dateTimeRange = dateTimeRangeCalculator.getDateTimeRangeAll();
        break;
      default:
        throw new Error('Invalid date range preset.');
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[(_vm.ready)?_c('v-data-table',{attrs:{"headers":_vm.reportHeaders,"items":_vm.data,"search":_vm.search,"height":_vm.reportHeight,"fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value,class:{
              'text-right': !['category', 'subCategory'].includes(header.value),
            },attrs:{"colspan":header.width}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)]),_c('tbody',[_c('tr',[_c('th',{style:(_vm.tableBgColor)}),_vm._l((headers),function(header,i){return [(!['category', 'subCategory'].includes(header.value))?[_c('th',{key:("week_" + i),staticClass:"text-right"},[_vm._v(" Selected Dates ")]),_c('th',{key:("all_" + i),staticClass:"text-right"},[_vm._v(" Total ")])]:_vm._e()]})],2),_vm._l((items),function(item,i){return [(_vm.renderPPEDataRow(item.sites.grand_total, _vm.ppeAllData[i].sites.grand_total))?_c('tr',{key:("category_" + i),class:{
              'font-weight-bold category-highlight': !item.subCategory,
              'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark && i === items.length - 1,
              'yellow darken-3': _vm.$vuetify.theme.dark && i === items.length - 1,
            }},[_c('td',{style:(_vm.tableBgColor)},[(!item.subCategory)?_c('span',[_vm._v(_vm._s(item.category))]):_c('span',[_vm._v("-- "+_vm._s(item.subCategory))])]),_vm._l((item.sites),function(siteData,siteKey,siteIndex){return [_c('td',{key:(siteKey + "_week"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(_vm.formatComma(siteData.shipped))+" ")]),_c('td',{key:(siteKey + "_all"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(_vm.formatComma(_vm.ppeAllData[i].sites[siteKey].shipped))+" ")])]})],2):_vm._e()]})],2),_c('tbody',[(_vm.rushAllData.length)?_c('tr',[_c('td',{staticClass:"spacer-row",style:(_vm.tableBgColor),attrs:{"colspan":headers.reduce(function (acc, o) { return acc += o.width; }, 0)}})]):_vm._e(),_c('tr',[_c('th',{style:(_vm.tableBgColor)},[_vm._v(" Order Type ")]),_vm._l((headers),function(header){return [(!['category', 'subCategory'].includes(header.value))?_c('th',{key:header.value,attrs:{"colspan":header.width}},[_c('div',{class:{
                  'text-right': header.value !== 'ordertype',
                }},[_vm._v(" "+_vm._s(header.text)+" ")])]):_vm._e()]})],2),_c('tr',[_c('th',{style:(_vm.tableBgColor)}),_vm._l((headers),function(header,i){return [(!['category', 'subCategory'].includes(header.value))?[_c('th',{key:("week_" + i),staticClass:"text-right"},[_vm._v(" Selected Dates ")]),_c('th',{key:("all_" + i),staticClass:"text-right"},[_vm._v(" Total ")])]:_vm._e()]})],2),_vm._l((_vm.rushSelectedDatesData),function(item,i){return _c('tr',{key:item.ordertype,class:{
            'font-weight-bold': i === _vm.rushSelectedDatesData.length - 1,
            'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark && i === _vm.rushSelectedDatesData.length - 1,
            'yellow darken-3': _vm.$vuetify.theme.dark && i === _vm.rushSelectedDatesData.length - 1,
          }},[_c('td',{staticClass:"font-weight-bold",style:(_vm.tableBgColor),attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(item.ordertype)+" ")]),_vm._l((item.sites),function(siteData,siteKey,siteIndex){return [(_vm.rushAllData.length && item.ordertype === 'Rush Order')?[_c('td',{key:(siteKey + "_rush_week"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(siteData.count_rush_shipped)+" ")]),_c('td',{key:(siteKey + "_rush_all"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(_vm.rushAllData[i].sites[siteKey].count_rush_shipped)+" ")])]:(_vm.rushAllData.length && item.ordertype === 'Standard Order')?[_c('td',{key:(siteKey + "_standard_week"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(siteData.count_standard_shipped)+" ")]),_c('td',{key:(siteKey + "_standard_all"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(_vm.rushAllData[i].sites[siteKey].count_standard_shipped)+" ")])]:(_vm.rushAllData.length)?[_c('td',{key:(siteKey + "_total_week"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(siteData.count_orders_shipped)+" ")]),_c('td',{key:(siteKey + "_total_all"),class:_vm.getSiteColumnStyle(siteKey, siteIndex)},[_vm._v(" "+_vm._s(_vm.rushAllData[i].sites[siteKey].count_orders_shipped)+" ")])]:_vm._e()]})],2)})],2)]}}],null,false,118208392)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
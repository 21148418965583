var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.className},[(!_vm.loading)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"search":_vm.search,"height":_vm.reportHeight,"fixed-header":"","disable-sort":"","disable-pagination":"","hide-default-footer":"","hide-default-header":"","dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(header){return _c('th',{key:header.value},[_c('div',{class:{
                'text-right': header.value === 'peakWarehouse',
              }},[_vm._v(" "+_vm._s(header.text)+" ")])])}),0)])]}},(_vm.rawData.length)?{key:"body",fn:function(ref){
              var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:item.sku,class:{
            'yellow-lighten-5-bg-only': !_vm.$vuetify.theme.dark && i === items.length - 1,
            'yellow-darken-3-bg-only': _vm.$vuetify.theme.dark && i === items.length - 1,
            'font-weight-bold': i === items.length - 1,
          }},[_c('td',{staticClass:"align-start"},[_vm._v(" "+_vm._s(item.customCategory)+" ")]),_c('td',{class:{
              'text-right font-weight-bold': true,
            }},[_vm._v(" "+_vm._s(_vm.formatComma(item.peakWarehouse))+" ")]),_c('td',[((i === 0)
                || (items[i - 1].category !== item.category
                  || items[i -1].customCategory !== item.customCategory))?_c('span',[_vm._v(_vm._s(item.category))]):_vm._e()]),_c('td',_vm._l((item.subCategories.split('\n')),function(subCategory){return _c('div',{key:subCategory},[_vm._v(" "+_vm._s(subCategory)+" ")])}),0)])}),0)]}}:null],null,true)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
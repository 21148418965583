









































import Vue, { PropOptions } from 'vue';

export enum DateTimeRangePreset {
  DAY,
  WEEK,
  MONTH,
  ALL,
}

export default Vue.extend({
  name: 'DateTimeRangePresetButtons',
  props: {
    value: {
      type: Number,
      default: null,
    } as PropOptions<number | null>,
  },
  data() {
    return {
      activePreset: 0,
      preset: DateTimeRangePreset,
    };
  },
  methods: {
    isActive(preset: DateTimeRangePreset): boolean {
      return this.value === preset;
    },
    onClick(preset: DateTimeRangePreset): void {
      this.$emit('input', preset);
      this.$emit('click', preset);
    },
  },
});

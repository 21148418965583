










































































































import { Component, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import ReportUtil from '@/lib/ReportUtil';
import reportDownloader from '@/lib/ReportDownloader';
import dateTimeRangeCalculator from '@/lib/DateTimeRangeCalculator';
import { ReportComponent, ReportAction } from './classes/ReportComponent';
import { DataTableHeader } from 'vuetify';
import { EnabledReportControls } from '../../../store/modules/reports';

type REOCPPESupplyReportRawData = {
  service_category: string;
  dist_channel: string;
  item_category: string;
  packs_created_main: number;
  units_created_main: number;
  packs_back_ordered_main: number;
  units_back_ordered_main: number;
  packs_shipped_main: number;
  units_shipped_main: number;
  fill_rate_by_packs: number;
  fill_rate_by_units: number;
  shipped_value: number;
};

type REOCPPESupplyReportData = {
  [itemCategory: string]: any;
  serviceCategory: string;
  distChannel: string;
};

@Component({
  name: 'REOCPPESupplyReport',
})
export default class REOCPPESupplyReport extends ReportComponent {
  public readonly enabledControls: EnabledReportControls = {
    search: true,
    dateTimeRange: true,
    distChannels: false,
    displayByContainers: true,
  };

  public readonly className = 'reoc-ppe-supply-report';

  public readonly name = 'REOC PPE Supply Report';

  public readonly description = 'Summary of PPE supplied by product category, organized by area of service.';

  public actions: ReportAction[] = [
    {
      label: 'Export as CSV',
      value: 'export_csv',
      icon: 'mdi-file-delimited',
    },
    {
      label: 'Export Raw Data',
      value: 'export_raw_data',
      icon: 'mdi-download',
    },
    {
      label: 'Export as PDF',
      value: 'export_pdf',
      icon: 'mdi-file-delimited',
    },
  ];

  protected data: REOCPPESupplyReportData[] = [];

  /**
   * Raw, original data pulled from API for this Report
   */
  private rawData: any[] = [];

  private headers: DataTableHeader[] = [];

  protected get tableBgColor() {
    return {
      background: (this.$vuetify.theme.dark) ? '#1E1E1E' : 'white',
    };
  }

  /**
   * Callback for date-range watcher; reloads data from server
   * with new query parameters
   */
  @Watch('dateTimeRange')
  protected async onControlChange() {
    this.$nextTick(() => this.debouncedInit());
  }

  /**
   * Callback for displayByContainers watcher; recalculates data for display
   */
  @Watch('displayByContainers')
  protected onDisplayByContainersChange() {
    this.data = this.formatData(this.rawData);
  }

  public created() {
    this.toggleControls();
    this.init();
  }

  public execute(command: string): void {
    if (command === 'export_csv') {
      this.downloadCsvReport();
      return;
    }
    if (command === 'export_raw_data') {
      this.downloadCsvRawData();
      return;
    }
    if (command === 'export_pdf') {
      this.downloadPdfReport();
    }
  }

  protected pdfHeight() {
    return this.tableDimensions().height * 0.24;
  }

  protected pdfWidth() {
    return this.tableDimensions().width * 0.3;
  }

  protected async fetchData() {
    if (!this.dateTimeRange) throw Error('Unable to fetch data without `dateTimeRange`');
    const { startDateTime, endDateTime } = dateTimeRangeCalculator.calcDateTimeRange(this.dateTimeRange);
    const response = await this.reportService.api.communitySitesPpeShipped({
      authentication_token: this.userService.getActiveToken(),
      report_params: {
        startDate: startDateTime,
        endDate: endDateTime,
      },
    });
    this.rawData = response;
    return this.formatData(response);
  }

  /**
   * Get column style
   */
  protected getColumnStyle(colKey: any, colIndex: any) {
    if (colKey === '_total') {
      return {
        'yellow-lighten-5-bg-only': !this.$vuetify.theme.dark,
        'yellow-darken-3-bg-only': this.$vuetify.theme.dark,
        'text-right': true,
        'font-weight-bold': true,
      };
    }
    if (colIndex % 2) {
      return {
        'text-right': true,
      };
    }
    return {
      'blue-lighten-5-bg-only': !this.$vuetify.theme.dark,
      'primary-darken-3-bg-only': this.$vuetify.theme.dark,
      'text-right': true,
    };
  }

  /**
   * Load dynamic headers for Report
   */
  private loadHeaders(itemCategories: string[]) {
    this.headers =[
      {
        text: 'Area of Service',
        value: 'serviceCategory',
      },
      // {
      //   text: 'Distribution Channel',
      //   value: 'distChannel',
      // },
    ];
    // add item categories as header columns
    itemCategories.forEach((category) => {
      this.headers.push({
        text: category,
        value: category,
        width: 3,
      });
    });
    this.headers.push({
      text: 'Total',
      value: '_total',
      width: 3,
    });
  }

  /**
   * Format data for Report before rendering in table
   */
  private formatData(data: REOCPPESupplyReportRawData[]) {
    const nestedReportData = d3
      .nest()
      .key((d: any) => d.service_category).sortKeys(d3.ascending)
      .key((d: any) => d.dist_channel)
      .key((d: any) => d.item_category)
      .rollup((leaves: any) => leaves[0])
      .entries(data);

    // get unique set of order priorities
    const itemCategories: string[] = nestedReportData[nestedReportData.length - 1].values[0].values
      .map((category: any) => category.key);

    this.loadHeaders(itemCategories);

    /*
    pivot report data to summarize item category per
    service category group and distribution channel

    structure generated:
    [
      {
        serviceCategory: [string | null],
        distChannel: [string | null],
        EYE PROTECTION: { packs_shipped_main: [number], ...},
        MASKS: { packs_shipped_main: [number], ...},
        ...(additional item categories)
      },
      ...(additional service categories)
    ]

    note: empty string in distChannel columns represent subtotal rows for service category

    example:
       { serviceCategory: 'TEST', distChannel: '' } => represents the subtotal row for TEST category
    */
    const flatReportData = nestedReportData.flatMap((serviceCategory) => {
      return serviceCategory.values.map((distChannel: any) => {
        const categoryRow: REOCPPESupplyReportData = {
          serviceCategory: serviceCategory.key,
          distChannel: distChannel.key,
        };
        if (this.displayByContainers) {
          const totalCreated = d3.sum(distChannel.values, (c: any) => c.value.packs_created_main);
          const totalBackOrdered = d3.sum(distChannel.values, (c: any) => c.value.packs_back_ordered_main);
          const totalFillRate = ReportUtil.calculateFillRate(totalCreated, totalBackOrdered);
          categoryRow._total = {
            shipped: d3.sum(distChannel.values, (c: any) => c.value.packs_shipped_main),
            fill_rate: ReportUtil.floatToPercent(totalFillRate),
            value: d3.sum(distChannel.values, (c: any) => c.value.shipped_value),
          };
          distChannel.values.forEach((itemCategory: any) => {
            categoryRow[itemCategory.key] = {
              shipped: itemCategory.value.packs_shipped_main,
              fill_rate: ReportUtil.floatToPercent(itemCategory.value.fill_rate_by_packs),
              value: itemCategory.value.shipped_value,
            };
          });
        } else {
          const totalCreated = d3.sum(distChannel.values, (c: any) => c.value.units_created_main);
          const totalBackOrdered = d3.sum(distChannel.values, (c: any) => c.value.units_back_ordered_main);
          const totalFillRate = ReportUtil.calculateFillRate(totalCreated, totalBackOrdered);
          categoryRow._total = {
            shipped: d3.sum(distChannel.values, (c: any) => c.value.units_shipped_main),
            fill_rate: ReportUtil.floatToPercent(totalFillRate),
            value: d3.sum(distChannel.values, (c: any) => c.value.shipped_value),
          };
          distChannel.values.forEach((itemCategory: any) => {
            categoryRow[itemCategory.key] = {
              shipped: itemCategory.value.units_shipped_main,
              fill_rate: ReportUtil.floatToPercent(itemCategory.value.fill_rate_by_units),
              value: itemCategory.value.shipped_value,
            };
          });
        }
        return categoryRow;
      });
    });
    return flatReportData;
  }

  /**
   * Download CSV file of Report data
   */
  private downloadCsvReport() {
    if (!this.dateTimeRange) throw Error('Unable to download CSV without `dateTimeRange`');
    const dateRangeText = `${this.dateTimeRange.startDate} - ${this.dateTimeRange.endDate}`;
    const filename = `${this.name} [${dateRangeText}]`;
    const headers = ['Service Category'];
    const labels = [''];
    this.headers.slice(1).forEach((header) => {
      headers.push(header.text);
      headers.push('');
      headers.push('');

      labels.push('Shipped');
      labels.push('Fill Rate');
      labels.push('Value');
    });
    const data = [headers, labels].concat(this.data.map((row, i) => {
      const out = [];
      if (!row.distChannel) {
        out.push(row.serviceCategory);
      } else {
        out.push(row.distChannel);
      }
      if (i == this.data.length - 1) out[0] = 'Grand Total';
      this.headers.slice(1).forEach((header) => {
        out.push(row[header.value].shipped);
        out.push(row[header.value].fill_rate);
        out.push(row[header.value].value);
      });
      return out;
    }));
    reportDownloader.downloadCsv(filename, data);
  }

  /**
   * Download Csv Raw Data
   */
  private downloadCsvRawData() {
    const { startDate, endDate } = this.dateTimeRange;
    const filename = `${this.name} [${startDate} - ${endDate}] (raw)`;
    const headers = Object.keys(this.rawData[0]);
    const data = [headers].concat(this.rawData.map(row => Object.values(row)));
    reportDownloader.downloadCsv(filename, data);
  }
}
